(function($) {
    $(document).on('turbolinks:load', function () {
        $('#nav_toggle').on('click', function() {
            $('body').toggleClass('open');
        });
    });
})(jQuery);

$(document).on('turbolinks:load', function () {
 var state = false;
 var scrollpos;

 $('#nav_toggle').on('click', function(){
   if(state == false) {
     scrollpos = $(window).scrollTop();
     $("html").css({'overflow-y':'hidden','min-height':60*scrollpos});
     $(".wrap").css({'position':'fixed','width':'100%','top': -1*scrollpos});
     $("body").addClass('open');
     state = true;
   } else {
     $("body").removeClass('open');
     $(".wrap").css({'position':'','width':'','top': ''});
     $("html").css({'overflow-y':'','min-height':''});
     window.scrollTo( 0 , scrollpos );
     state = false;
   }
 });
});
